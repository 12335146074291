export const TAG_MAX_LENGTH = 255;

export const TAG_SORT_OPTIONS = [
  {
    label: 'Name (A > Z)',
    param: 'name_asc'
  },
  {
    label: 'Name (Z > A)',
    param: 'name_desc'
  }
];

export const TAG_EVENTS = {
  navigationRefresh: 'tag-nav-refresh'
};
